import React, { useReducer, useCallback, useMemo, useContext } from 'react';

interface Props {
  openNavItemIds: Array<string>;
}

interface ProviderValueProps extends Props {
  addOpenNavItemId: (payload: string) => void;
  removeClosedNavItemId: (payload: string) => void;
}

const initialState: Props = {
  openNavItemIds: []
};

const REDUCER_ACTIONS = {
  ADD_OPEN_NAV_ITEM_ID: 'ADD_OPEN_NAV_ITEM_ID',
  REMOVE_CLOSED_NAV_ITEM_ID: 'REMOVE_CLOSED_NAV_ITEM_ID'
};

type ReducerAction = {
  type: string;
  payload: string;
};

const reducer = (state: Props, action: ReducerAction): Props => {
  switch (action.type) {
    case REDUCER_ACTIONS.ADD_OPEN_NAV_ITEM_ID: {
      return state.openNavItemIds.find(item => item === action.payload)
        ? state
        : {
            ...state,
            openNavItemIds: [...state.openNavItemIds, action.payload]
          };
    }
    case REDUCER_ACTIONS.REMOVE_CLOSED_NAV_ITEM_ID: {
      return !state.openNavItemIds.find(item => item === action.payload)
        ? state
        : {
            ...state,
            openNavItemIds: state.openNavItemIds.filter(item => item !== action.payload)
          };
    }
    default:
      return state;
  }
};

const SideNavigationContext = React.createContext(initialState);

const useSideNavigation = (): ProviderValueProps => {
  return { ...useContext(SideNavigationContext) } as ProviderValueProps;
};

const SideNavigationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState
  });

  const addOpenNavItemId = useCallback(
    (payload: string) => {
      dispatch({
        type: REDUCER_ACTIONS.ADD_OPEN_NAV_ITEM_ID,
        payload
      });
    },
    [dispatch]
  );

  const removeClosedNavItemId = useCallback(
    (payload: string) => {
      dispatch({
        type: REDUCER_ACTIONS.REMOVE_CLOSED_NAV_ITEM_ID,
        payload
      });
    },
    [dispatch]
  );

  const contextValue = useMemo(() => {
    return {
      ...state,
      addOpenNavItemId,
      removeClosedNavItemId
    };
  }, [state, addOpenNavItemId, removeClosedNavItemId]) as ProviderValueProps;

  return <SideNavigationContext.Provider value={contextValue}>{children}</SideNavigationContext.Provider>;
};

export { SideNavigationProvider, useSideNavigation, SideNavigationContext };
