import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';

import React from 'react';
import { ThemeProvider } from '@components/ThemeProvider';
import { Breakpoints } from '@cvent/carina/components/Breakpoints';
import { GlobalStyles } from '@components/GlobalStyles';
import { LocalizationProvider } from 'nucleus-text';
import locales from '../../locales';
import { useQuery } from '@apollo/client';
import { GetLocaleDocument } from '@operations/operations';
import { SideNavigationProvider } from '@context/SideNavigationContext';
import { __IS_SERVER_MODE__ } from '@server/isServerMode';
import AppFeatureContext from '@context/AppFeaturesContext';
import AppPreferencesContext from '@src/context/AppPreferencesContext';

export default function AppProviders({ children }: { children: JSX.Element }): JSX.Element {
  const { data } = useQuery(GetLocaleDocument);
  const locale = data?.locale;

  return (
    <AppFeatureContext.Provider value={children.props.appFeatures}>
      <AppPreferencesContext.Provider value={children.props.applicationPreferences}>
        <ThemeProvider>
          <LocalizationProvider locale={locale} clientSideLocales={locales} serverSideLocales={locales}>
            <GlobalStyles />
            <Breakpoints s={500} m={1000} l={1175} ssrSize="m" key={__IS_SERVER_MODE__ ? 'ssr' : 'csr'}>
              <SideNavigationProvider>{children}</SideNavigationProvider>
            </Breakpoints>
          </LocalizationProvider>
        </ThemeProvider>
      </AppPreferencesContext.Provider>
    </AppFeatureContext.Provider>
  );
}
