import React from 'react';
import { ThemeProvider as CarinaThemeProvider } from '@cvent/carina/components/ThemeProvider/ThemeProvider';
import { ThemeProvider as V0ThemeProvider } from '@cvent/carina-theme-provider';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';

const defaultTheme = getDefaultTheme();
export const ThemeProvider = ({ children }: { children: JSX.Element }): JSX.Element => (
  <CarinaThemeProvider theme={defaultTheme}>
    <V0ThemeProvider theme={defaultTheme}>{children}</V0ThemeProvider>
  </CarinaThemeProvider>
);

export default ThemeProvider;
