import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  createHttpLink,
  from
} from '@apollo/client';

import type { GraphQLError } from 'graphql';
import { __IS_SERVER_MODE__ } from '@server/isServerMode';
import { localeVar } from './reactiveVariables';
import { onError } from '@apollo/client/link/error';
import { fetchClientWithRetries } from '@src/fetchClient';

interface UnauthorizedError extends GraphQLError {
  errorInfo: {
    code: string;
  };
}

/**
 * Wrapper around the fetch request to retry if the server returns a busy response.
 * @param url The url for the request
 * @param options Any fetch options to include in the fetch request
 */
const linkFetchAndRetryIfServerBusy = (url: string, options: RequestInit): Promise<Response> => {
  return fetchClientWithRetries.fetch(url, options);
};

/**
 * Creates an apollo link to talk to the attendee hub graph. If using on the server side,
 * provide the cookie header with the auth token.
 */
const getAttendeeHubLink = (headers?: Record<string, string>): ApolloLink =>
  createHttpLink({
    uri: __IS_SERVER_MODE__ ? `${process.env.ATTENDEE_HUB_GRAPH_ENDPOINT}` : '/api/attendeeHubGraphql',
    credentials: 'same-origin',
    headers: {
      authorization: `API_KEY ${process.env.API_KEY}`,
      ...headers
    },
    // @ts-ignore
    fetch: linkFetchAndRetryIfServerBusy
  });

/**
 * Creates an apollo link to talk to the branded app graph. If using on the server side,
 * provide the cookie header with the auth token.
 */
const getBrandedAppLink = (headers?: Record<string, string>): ApolloLink =>
  createHttpLink({
    uri: __IS_SERVER_MODE__ ? 'http://localhost:3000/api/graphql' : '/api/graphql',
    credentials: 'same-origin',
    headers,
    // @ts-ignore
    fetch: linkFetchAndRetryIfServerBusy
  });

/**
 * Setup the Cache for the apollo client including any custom type policies
 */
const createCache = (): InMemoryCache => {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          locale: {
            read(): string {
              return localeVar();
            }
          }
        }
      },
      App: {
        merge: true
      }
    }
  });
};

/**
 * Custom error link to handle 401 errors thrown by the graph. We've seen 401s at the component level that are
 * not handled and sometimes keep re-trying (i.e. subscriptions). This error link catches all graphQL errors
 * globally and handles 401s by redirecting the user to the login screen
 */
const onErrorLink = onError(error => {
  const { networkError, graphQLErrors } = error;

  if (typeof window === 'undefined') {
    return;
  }

  if (networkError) {
    const networkServerError = networkError as ServerError;
    // 403 is returned if CRSF token is expired. A reload will trigger a new CSRF token to be generated
    // 401 is returned if CSRF token is invalid. Redirect to logout page if provided
    if (networkServerError.statusCode === 403) {
      location.reload();
    } else if (networkServerError.statusCode === 401) {
      if (typeof networkServerError.result === 'object' && 'redirectUrl' in networkServerError.result) {
        window.location.href = networkServerError.result.redirectUrl;
      } else {
        location.reload();
      }
    }
  }

  // The authorization lambda only returns a `Not authorized` message, so we use it here to check if any of the
  // errors returned by the graphQL operation is related to authorization.
  const unAuthorizedError = graphQLErrors?.find(e => {
    const unauthorizedError = e as UnauthorizedError;

    if (unauthorizedError == null) {
      return;
    }
    return (
      unauthorizedError.errorInfo?.code === '401' ||
      unauthorizedError.message.includes('Not authorized') ||
      unauthorizedError.message.includes('Unauthorized')
    );
  });

  // If an unauthorized error is found; reload the page, which will take the user back to login
  if (unAuthorizedError) {
    location.reload();
  }
});

/**
 * Gets the default cache and apollo link to setup an apollo client.
 *
 * @param headers A list of headers to include in all calls to each underlying graph. Useful
 *                to provide the cookie header with a bearer token when setting up a server client.
 */
export function getServerApolloOptions(headers?: Record<string, string>): ApolloClientOptions<NormalizedCacheObject> {
  return {
    cache: createCache(),
    link: from([
      onErrorLink,
      ApolloLink.split(
        operation => operation.getContext().clientName === 'attendee-hub-remote',
        getAttendeeHubLink(headers),
        getBrandedAppLink(headers)
      )
    ])
  };
}

/**
 * Gets the default cache and apollo link to setup an apollo client.
 *
 * @param headers A list of headers to include in all calls to each underlying graph. Useful
 *                to provide the cookie header with a bearer token when setting up a server client.
 */
export function getClientApolloOptions(headers?: Record<string, string>): ApolloClientOptions<NormalizedCacheObject> {
  return {
    cache: createCache(),
    link: from([
      onErrorLink,
      ApolloLink.split(
        operation => operation.getContext().clientName === 'attendee-hub-remote',
        getAttendeeHubLink(headers),
        getBrandedAppLink(headers)
      )
    ])
  };
}
