import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Void: any;
};

export type AndroidApplicationPreferences = {
  __typename?: 'AndroidApplicationPreferences';
  enabled: Scalars['Boolean'];
  signingType?: Maybe<SigningType>;
};

export type AndroidApplicationPreferencesInput = {
  enabled: Scalars['Boolean'];
  signingType?: InputMaybe<SigningType>;
};

export type AndroidImages = {
  __typename?: 'AndroidImages';
  appIcon?: Maybe<MediaLibraryImage>;
  featureGraphic?: Maybe<MediaLibraryImage>;
  id: Scalars['ID'];
};

export type AndroidImagesInput = {
  appIcon?: InputMaybe<MediaLibraryImageInput>;
  featureGraphic?: InputMaybe<MediaLibraryImageInput>;
};

export type AndroidSigning = {
  __typename?: 'AndroidSigning';
  keystore?: Maybe<Keystore>;
};

export type App = {
  __typename?: 'App';
  appDetails: AppDetails;
  applicationPreferences: ApplicationPreferences;
  id: Scalars['ID'];
  name: Scalars['String'];
  pushProvisioning: PushProvisioning;
  signing: Signing;
  status: AppStatus;
  storeSettings: StoreSettings;
  type: Scalars['String'];
};

export type AppDetails = {
  __typename?: 'AppDetails';
  androidBundleId?: Maybe<Scalars['String']>;
  androidImages: AndroidImages;
  iOSBundleId?: Maybe<Scalars['String']>;
  iOSImages: IosImages;
  id: Scalars['ID'];
  lastModified?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  splashScreen?: Maybe<MediaLibraryImage>;
  theme: Theme;
};

export type AppDetailsInput = {
  androidBundleId?: InputMaybe<Scalars['String']>;
  iOSBundleId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AppDetailsPage = {
  __typename?: 'AppDetailsPage';
  appDetails?: Maybe<AppDetails>;
  id: Scalars['ID'];
  storeSettings?: Maybe<StoreSettings>;
};

export type AppListItem = {
  __typename?: 'AppListItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AppStatus = {
  __typename?: 'AppStatus';
  android?: Maybe<Android>;
  appDetails: TaskStatus;
  iOS?: Maybe<IOs>;
  id: Scalars['ID'];
  signingType?: Maybe<SigningType>;
  splashScreen: TaskStatus;
  theme: TaskStatus;
};

export type ApplicationBuilds = {
  __typename?: 'ApplicationBuilds';
  builds: Array<Maybe<Build>>;
  id: Scalars['ID'];
  nextToken?: Maybe<Scalars['String']>;
  previousToken?: Maybe<Scalars['String']>;
};

export type ApplicationBuildsBuildsArgs = {
  buildInput: BuildInput;
};

export type ApplicationPreferences = {
  __typename?: 'ApplicationPreferences';
  android: AndroidApplicationPreferences;
  applicationId: Scalars['ID'];
  distributionType: DistributionType;
  environmentParameters: EnvironmentParameters;
  iOS: IosApplicationPreferences;
  preferencesSet: Scalars['Boolean'];
};

export type ApplicationPreferencesInput = {
  android: AndroidApplicationPreferencesInput;
  distributionType: DistributionType;
  environmentParameters: EnvironmentParameterInput;
  iOS: IosApplicationPreferencesInput;
};

export type BranchConfiguration = {
  __typename?: 'BranchConfiguration';
  branchAlternateDomain?: Maybe<Scalars['String']>;
  branchDomain?: Maybe<Scalars['String']>;
  branchKey?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type BranchLinkData = {
  __typename?: 'BranchLinkData';
  androidUrl?: Maybe<Scalars['String']>;
  iOSUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sha256?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['String']>;
};

export type BranchLinkDataInput = {
  androidUrl?: InputMaybe<Scalars['String']>;
  iOSUrl?: InputMaybe<Scalars['String']>;
  sha256?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['String']>;
};

export type Build = {
  __typename?: 'Build';
  buildId: Scalars['ID'];
  buildJobId?: Maybe<Scalars['String']>;
  buildRequestId?: Maybe<Scalars['String']>;
  buildType: BuildType;
  createdDateTime: Scalars['String'];
  cventVersion?: Maybe<Scalars['String']>;
  distributionBuildType: Scalars['String'];
  distributionType: DistributionType;
  downloadable: Scalars['Boolean'];
  errorDescription?: Maybe<Scalars['String']>;
  formattedCreatedDateTime: Scalars['String'];
  formattedUpdatedDateTime: Scalars['String'];
  platform?: Maybe<Platform>;
  signingType: SigningType;
  status: Status;
  updatedDateTime: Scalars['String'];
  version: Scalars['String'];
};

export type BuildInput = {
  buildType?: InputMaybe<Array<InputMaybe<DistributionBuildType>>>;
  limit: Scalars['Int'];
  platform?: InputMaybe<Array<InputMaybe<Platform>>>;
  status?: InputMaybe<Array<InputMaybe<Status>>>;
  token?: InputMaybe<Scalars['String']>;
};

export type BuildQueueStatus = {
  __typename?: 'BuildQueueStatus';
  status: BuildQueueStatuses;
};

export enum BuildQueueStatuses {
  Available = 'Available',
  Full = 'Full',
  Unavailable = 'Unavailable'
}

export enum BuildType {
  AndroidAab = 'AndroidAAB',
  AndroidApk = 'AndroidAPK',
  Iosipa = 'IOSIPA',
  IosSimulator = 'IOSSimulator'
}

export enum BuildTypes {
  AndroidAab = 'AndroidAAB',
  AndroidApk = 'AndroidAPK',
  Iosipa = 'IOSIPA',
  IosSimulator = 'IOSSimulator'
}

export type BuildsCancelInput = {
  buildJobId?: InputMaybe<Scalars['String']>;
  buildRequestId?: InputMaybe<Scalars['String']>;
};

export type BuildsStartInput = {
  buildTypes: Array<BuildTypes>;
  cventVersion: Scalars['String'];
  version: Scalars['String'];
};

export type Certificate = {
  __typename?: 'Certificate';
  commonName?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Date']>;
  filename?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Fingerprint>;
  issuer?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  organizationalUnit?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type CertificateFingerprint = {
  __typename?: 'CertificateFingerprint';
  sha1?: Maybe<Scalars['String']>;
  sha256?: Maybe<Scalars['String']>;
};

export enum CodeEnvironmentType {
  Dev = 'Dev',
  Internal = 'Internal',
  Production = 'Production'
}

export type CventReleaseVersions = {
  __typename?: 'CventReleaseVersions';
  versions: Array<Scalars['String']>;
};

export enum DistributionBuildType {
  EnterpriseAndroidAab = 'Enterprise_AndroidAAB',
  EnterpriseAndroidApk = 'Enterprise_AndroidAPK',
  EnterpriseIosipa = 'Enterprise_IOSIPA',
  IosSimulator = 'IOSSimulator',
  StoreAndroidAab = 'Store_AndroidAAB',
  StoreAndroidApk = 'Store_AndroidAPK',
  StoreIosipa = 'Store_IOSIPA'
}

export enum DistributionType {
  Enterprise = 'Enterprise',
  Store = 'Store'
}

export type EnvironmentParameterInput = {
  codeEnvironment?: InputMaybe<CodeEnvironmentType>;
};

export type EnvironmentParameters = {
  __typename?: 'EnvironmentParameters';
  codeEnvironment?: Maybe<CodeEnvironmentType>;
};

export type Fingerprint = {
  __typename?: 'Fingerprint';
  sha1?: Maybe<Scalars['String']>;
  sha256?: Maybe<Scalars['String']>;
};

export type IosApplicationPreferences = {
  __typename?: 'IOSApplicationPreferences';
  enabled: Scalars['Boolean'];
  signingType?: Maybe<SigningType>;
};

export type IosApplicationPreferencesInput = {
  enabled: Scalars['Boolean'];
  signingType?: InputMaybe<SigningType>;
};

export type IosImages = {
  __typename?: 'IOSImages';
  appIcon?: Maybe<MediaLibraryImage>;
  id: Scalars['ID'];
  storeIcon?: Maybe<MediaLibraryImage>;
};

export type IosImagesInput = {
  appIcon?: InputMaybe<MediaLibraryImageInput>;
  storeIcon?: InputMaybe<MediaLibraryImageInput>;
};

export type IosSigning = {
  __typename?: 'IOSSigning';
  certificate?: Maybe<Certificate>;
  provisioningProfile?: Maybe<ProvisioningProfile>;
};

export enum IosSigningType {
  CventManaged = 'CventManaged',
  CventManagedInternal = 'CventManagedInternal'
}

export type Keystore = {
  __typename?: 'Keystore';
  commonName?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Date']>;
  filename?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Fingerprint>;
  issuer?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  organizationalUnit?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type MainContact = {
  __typename?: 'MainContact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type MainContactInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type MediaLibraryImage = {
  __typename?: 'MediaLibraryImage';
  fileName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastModified?: Maybe<Scalars['Date']>;
  url?: Maybe<Scalars['String']>;
};

export type MediaLibraryImageInput = {
  fileName?: InputMaybe<Scalars['String']>;
  lastModified?: InputMaybe<Scalars['Date']>;
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  buildsCancel?: Maybe<Scalars['Void']>;
  buildsStart?: Maybe<Scalars['Void']>;
  updateAndroidImages?: Maybe<AndroidImages>;
  updateAppDetails?: Maybe<AppDetails>;
  updateAppDetailsPage?: Maybe<AppDetailsPage>;
  updateApplicationPreferences?: Maybe<ApplicationPreferences>;
  updateBranchLinkData?: Maybe<BranchLinkData>;
  updateIOSImages?: Maybe<IosImages>;
  updateSplashScreen?: Maybe<MediaLibraryImage>;
  updateStoreSettings?: Maybe<StoreSettings>;
  updateTheme?: Maybe<Theme>;
};

export type MutationBuildsCancelArgs = {
  id: Scalars['ID'];
  input: BuildsCancelInput;
};

export type MutationBuildsStartArgs = {
  id: Scalars['ID'];
  input: BuildsStartInput;
};

export type MutationUpdateAndroidImagesArgs = {
  id: Scalars['ID'];
  input: AndroidImagesInput;
};

export type MutationUpdateAppDetailsArgs = {
  id: Scalars['ID'];
  input: AppDetailsInput;
};

export type MutationUpdateAppDetailsPageArgs = {
  appDetailsInput: AppDetailsInput;
  id: Scalars['ID'];
  storeSettingsInput: StoreSettingsInput;
};

export type MutationUpdateApplicationPreferencesArgs = {
  id: Scalars['ID'];
  input: ApplicationPreferencesInput;
};

export type MutationUpdateBranchLinkDataArgs = {
  id: Scalars['ID'];
  input: BranchLinkDataInput;
};

export type MutationUpdateIosImagesArgs = {
  id: Scalars['ID'];
  input: IosImagesInput;
};

export type MutationUpdateSplashScreenArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<MediaLibraryImageInput>;
};

export type MutationUpdateStoreSettingsArgs = {
  id: Scalars['ID'];
  input: StoreSettingsInput;
};

export type MutationUpdateThemeArgs = {
  id: Scalars['ID'];
  input: ThemeInput;
};

export enum Platform {
  Android = 'Android',
  Ios = 'IOS'
}

export enum PreferFormula {
  Dark = 'Dark',
  Default = 'Default',
  Light = 'Light'
}

export type ProvisioningProfile = {
  __typename?: 'ProvisioningProfile';
  appIdName?: Maybe<Scalars['String']>;
  applicationIdentifierPrefix?: Maybe<Scalars['String']>;
  certificateFingerprint?: Maybe<CertificateFingerprint>;
  expirationDate?: Maybe<Scalars['Date']>;
  filename?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['Date']>;
  provisioningName?: Maybe<Scalars['String']>;
  provisioningType?: Maybe<ProvisioningType>;
  signingType?: Maybe<IosSigningType>;
  teamIdentifier?: Maybe<Scalars['String']>;
};

export enum ProvisioningType {
  AdHoc = 'AdHoc',
  InHouse = 'InHouse',
  Store = 'Store'
}

export type PushCertificate = {
  __typename?: 'PushCertificate';
  expiration?: Maybe<Scalars['Date']>;
  filename?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<CertificateFingerprint>;
  issuer?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['Date']>;
  name?: Maybe<Scalars['String']>;
  organizationalUnit?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export enum PushNotificationType {
  Certificate = 'Certificate',
  Token = 'Token'
}

export type PushProvisioning = {
  __typename?: 'PushProvisioning';
  certificate?: Maybe<PushCertificate>;
  id: Scalars['ID'];
  token?: Maybe<PushToken>;
};

export type PushToken = {
  __typename?: 'PushToken';
  filename?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['Date']>;
  teamId?: Maybe<Scalars['String']>;
  uploadDate?: Maybe<Scalars['Date']>;
};

export type Query = {
  __typename?: 'Query';
  app?: Maybe<App>;
  applicationBuilds?: Maybe<ApplicationBuilds>;
  apps?: Maybe<Array<Maybe<AppListItem>>>;
  branchConfiguration?: Maybe<BranchConfiguration>;
  branchLinkData?: Maybe<BranchLinkData>;
  buildQueueStatus?: Maybe<BuildQueueStatus>;
  cventReleaseVersions?: Maybe<CventReleaseVersions>;
  locale?: Maybe<Scalars['String']>;
};

export type QueryAppArgs = {
  id: Scalars['String'];
};

export type QueryApplicationBuildsArgs = {
  buildInput: BuildInput;
  id: Scalars['String'];
};

export type QueryBranchConfigurationArgs = {
  id: Scalars['String'];
};

export type QueryBranchLinkDataArgs = {
  id: Scalars['String'];
};

export type Signing = {
  __typename?: 'Signing';
  android?: Maybe<AndroidSigning>;
  iOS?: Maybe<IosSigning>;
  id: Scalars['ID'];
};

export enum SigningType {
  Cvent = 'Cvent',
  External = 'External'
}

export enum Status {
  Cancelled = 'Cancelled',
  Cancelling = 'Cancelling',
  Complete = 'Complete',
  Error = 'Error',
  InProgress = 'InProgress',
  Processing = 'Processing',
  Queued = 'Queued'
}

export type StoreSettings = {
  __typename?: 'StoreSettings';
  androidAccountName?: Maybe<Scalars['String']>;
  appleAccountName?: Maybe<Scalars['String']>;
  appleTeamId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModified?: Maybe<Scalars['Date']>;
  mainContact?: Maybe<MainContact>;
  privacyPolicyUrl?: Maybe<Scalars['String']>;
  sellerName?: Maybe<Scalars['String']>;
  storeDescription?: Maybe<Scalars['String']>;
  storeKeywords?: Maybe<Scalars['String']>;
  storeName?: Maybe<Scalars['String']>;
  storeShortDescription?: Maybe<Scalars['String']>;
  supportUrl?: Maybe<Scalars['String']>;
};

export type StoreSettingsInput = {
  androidAccountName?: InputMaybe<Scalars['String']>;
  appleAccountName?: InputMaybe<Scalars['String']>;
  appleTeamId?: InputMaybe<Scalars['String']>;
  mainContact?: InputMaybe<MainContactInput>;
  privacyPolicyUrl?: InputMaybe<Scalars['String']>;
  sellerName?: InputMaybe<Scalars['String']>;
  storeDescription?: InputMaybe<Scalars['String']>;
  storeKeywords?: InputMaybe<Scalars['String']>;
  storeName?: InputMaybe<Scalars['String']>;
  storeShortDescription?: InputMaybe<Scalars['String']>;
  supportUrl?: InputMaybe<Scalars['String']>;
};

export enum TaskStatus {
  Done = 'Done',
  Optional = 'Optional',
  Required = 'Required'
}

export type Theme = {
  __typename?: 'Theme';
  actionColor?: Maybe<Scalars['String']>;
  headerShape?: Maybe<ThemeHeaderShape>;
  id: Scalars['ID'];
  lastModified?: Maybe<Scalars['Date']>;
  preferFormula?: Maybe<PreferFormula>;
  primaryColor?: Maybe<Scalars['String']>;
  safeColorMode?: Maybe<Scalars['Boolean']>;
};

export enum ThemeHeaderShape {
  Standard = 'Standard',
  Wave = 'Wave'
}

export type ThemeInput = {
  actionColor?: InputMaybe<Scalars['String']>;
  headerShape?: InputMaybe<ThemeHeaderShape>;
  lastModified?: InputMaybe<Scalars['Date']>;
  preferFormula?: InputMaybe<PreferFormula>;
  primaryColor?: InputMaybe<Scalars['String']>;
  safeColorMode?: InputMaybe<Scalars['Boolean']>;
};

export type Android = {
  __typename?: 'android';
  images?: Maybe<TaskStatus>;
  signing?: Maybe<TaskStatus>;
};

export type IOs = {
  __typename?: 'iOS';
  images?: Maybe<TaskStatus>;
  pushNotification?: Maybe<TaskStatus>;
  signing?: Maybe<TaskStatus>;
};

export type GetAndroidImagesQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetAndroidImagesQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    id: string;
    appDetails: {
      __typename?: 'AppDetails';
      id: string;
      androidImages: {
        __typename?: 'AndroidImages';
        id: string;
        appIcon?: {
          __typename?: 'MediaLibraryImage';
          lastModified?: any | null;
          id?: string | null;
          fileName?: string | null;
          url?: string | null;
        } | null;
        featureGraphic?: {
          __typename?: 'MediaLibraryImage';
          lastModified?: any | null;
          id?: string | null;
          fileName?: string | null;
          url?: string | null;
        } | null;
      };
    };
  } | null;
};

export type GetAppQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetAppQuery = {
  __typename?: 'Query';
  app?: { __typename?: 'App'; id: string; name: string; type: string } | null;
};

export type GetAppDetailsQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetAppDetailsQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    id: string;
    name: string;
    type: string;
    appDetails: {
      __typename?: 'AppDetails';
      id: string;
      name?: string | null;
      iOSBundleId?: string | null;
      androidBundleId?: string | null;
      lastModified?: any | null;
      androidImages: {
        __typename?: 'AndroidImages';
        appIcon?: {
          __typename?: 'MediaLibraryImage';
          fileName?: string | null;
          url?: string | null;
          lastModified?: any | null;
        } | null;
        featureGraphic?: {
          __typename?: 'MediaLibraryImage';
          fileName?: string | null;
          url?: string | null;
          lastModified?: any | null;
        } | null;
      };
      iOSImages: {
        __typename?: 'IOSImages';
        appIcon?: {
          __typename?: 'MediaLibraryImage';
          fileName?: string | null;
          url?: string | null;
          lastModified?: any | null;
        } | null;
        storeIcon?: {
          __typename?: 'MediaLibraryImage';
          fileName?: string | null;
          url?: string | null;
          lastModified?: any | null;
        } | null;
      };
      theme: {
        __typename?: 'Theme';
        id: string;
        primaryColor?: string | null;
        actionColor?: string | null;
        headerShape?: ThemeHeaderShape | null;
        lastModified?: any | null;
      };
    };
    storeSettings: {
      __typename?: 'StoreSettings';
      id: string;
      appleAccountName?: string | null;
      androidAccountName?: string | null;
      storeName?: string | null;
      storeDescription?: string | null;
      storeShortDescription?: string | null;
      storeKeywords?: string | null;
      supportUrl?: string | null;
      privacyPolicyUrl?: string | null;
      appleTeamId?: string | null;
      sellerName?: string | null;
      lastModified?: any | null;
      mainContact?: {
        __typename?: 'MainContact';
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
      } | null;
    };
  } | null;
};

export type UpdateAppDetailsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AppDetailsInput;
}>;

export type UpdateAppDetailsMutation = {
  __typename?: 'Mutation';
  appDetails?: {
    __typename?: 'AppDetails';
    name?: string | null;
    iOSBundleId?: string | null;
    androidBundleId?: string | null;
  } | null;
};

export type UpdateSplashScreenMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<MediaLibraryImageInput>;
}>;

export type UpdateSplashScreenMutation = {
  __typename?: 'Mutation';
  splashScreen?: {
    __typename?: 'MediaLibraryImage';
    id?: string | null;
    fileName?: string | null;
    url?: string | null;
  } | null;
};

export type UpdateAndroidImagesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: AndroidImagesInput;
}>;

export type UpdateAndroidImagesMutation = {
  __typename?: 'Mutation';
  androidImages?: {
    __typename?: 'AndroidImages';
    id: string;
    appIcon?: {
      __typename?: 'MediaLibraryImage';
      id?: string | null;
      fileName?: string | null;
      url?: string | null;
    } | null;
    featureGraphic?: {
      __typename?: 'MediaLibraryImage';
      id?: string | null;
      fileName?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type UpdateIosImagesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: IosImagesInput;
}>;

export type UpdateIosImagesMutation = {
  __typename?: 'Mutation';
  iOSImages?: {
    __typename?: 'IOSImages';
    id: string;
    appIcon?: {
      __typename?: 'MediaLibraryImage';
      id?: string | null;
      fileName?: string | null;
      url?: string | null;
    } | null;
    storeIcon?: {
      __typename?: 'MediaLibraryImage';
      id?: string | null;
      fileName?: string | null;
      url?: string | null;
    } | null;
  } | null;
};

export type UpdateAppDetailsPageMutationVariables = Exact<{
  id: Scalars['ID'];
  appDetailsInput: AppDetailsInput;
  storeSettingsInput: StoreSettingsInput;
}>;

export type UpdateAppDetailsPageMutation = {
  __typename?: 'Mutation';
  appDetails?: {
    __typename?: 'AppDetails';
    id: string;
    name?: string | null;
    iOSBundleId?: string | null;
    androidBundleId?: string | null;
    lastModified?: any | null;
  } | null;
  storeSettings?: {
    __typename?: 'StoreSettings';
    id: string;
    appleAccountName?: string | null;
    androidAccountName?: string | null;
    storeName?: string | null;
    storeDescription?: string | null;
    storeShortDescription?: string | null;
    storeKeywords?: string | null;
    supportUrl?: string | null;
    privacyPolicyUrl?: string | null;
    appleTeamId?: string | null;
    sellerName?: string | null;
    lastModified?: any | null;
    mainContact?: {
      __typename?: 'MainContact';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
    } | null;
  } | null;
};

export type MediaLibraryImageFragmentFragment = {
  __typename?: 'MediaLibraryImage';
  id?: string | null;
  fileName?: string | null;
  url?: string | null;
};

export type GetOverviewPageQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetOverviewPageQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    id: string;
    name: string;
    applicationPreferences: {
      __typename?: 'ApplicationPreferences';
      applicationId: string;
      distributionType: DistributionType;
      preferencesSet: boolean;
      android: { __typename?: 'AndroidApplicationPreferences'; enabled: boolean; signingType?: SigningType | null };
      iOS: { __typename?: 'IOSApplicationPreferences'; enabled: boolean; signingType?: SigningType | null };
      environmentParameters: { __typename?: 'EnvironmentParameters'; codeEnvironment?: CodeEnvironmentType | null };
    };
    status: {
      __typename?: 'AppStatus';
      id: string;
      appDetails: TaskStatus;
      signingType?: SigningType | null;
      splashScreen: TaskStatus;
      theme: TaskStatus;
      iOS?: {
        __typename?: 'iOS';
        images?: TaskStatus | null;
        signing?: TaskStatus | null;
        pushNotification?: TaskStatus | null;
      } | null;
      android?: { __typename?: 'android'; images?: TaskStatus | null; signing?: TaskStatus | null } | null;
    };
  } | null;
};

export type GetApplicationBuildsQueryVariables = Exact<{
  id: Scalars['String'];
  buildInput: BuildInput;
}>;

export type GetApplicationBuildsQuery = {
  __typename?: 'Query';
  applicationBuilds?: {
    __typename?: 'ApplicationBuilds';
    id: string;
    nextToken?: string | null;
    previousToken?: string | null;
    builds: Array<{
      __typename?: 'Build';
      buildId: string;
      platform?: Platform | null;
      buildType: BuildType;
      distributionType: DistributionType;
      distributionBuildType: string;
      signingType: SigningType;
      createdDateTime: string;
      formattedCreatedDateTime: string;
      updatedDateTime: string;
      formattedUpdatedDateTime: string;
      version: string;
      cventVersion?: string | null;
      status: Status;
      downloadable: boolean;
      errorDescription?: string | null;
      buildJobId?: string | null;
      buildRequestId?: string | null;
    } | null>;
  } | null;
};

export type GetApplicationPreferencesQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetApplicationPreferencesQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    name: string;
    applicationPreferences: {
      __typename?: 'ApplicationPreferences';
      applicationId: string;
      distributionType: DistributionType;
      preferencesSet: boolean;
      android: { __typename?: 'AndroidApplicationPreferences'; enabled: boolean; signingType?: SigningType | null };
      iOS: { __typename?: 'IOSApplicationPreferences'; enabled: boolean; signingType?: SigningType | null };
      environmentParameters: { __typename?: 'EnvironmentParameters'; codeEnvironment?: CodeEnvironmentType | null };
    };
  } | null;
};

export type UpdateApplicationPreferencesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ApplicationPreferencesInput;
}>;

export type UpdateApplicationPreferencesMutation = {
  __typename?: 'Mutation';
  applicationPreferences?: {
    __typename?: 'ApplicationPreferences';
    applicationId: string;
    distributionType: DistributionType;
    preferencesSet: boolean;
    android: { __typename?: 'AndroidApplicationPreferences'; enabled: boolean; signingType?: SigningType | null };
    iOS: { __typename?: 'IOSApplicationPreferences'; enabled: boolean; signingType?: SigningType | null };
    environmentParameters: { __typename?: 'EnvironmentParameters'; codeEnvironment?: CodeEnvironmentType | null };
  } | null;
};

export type GetAppsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppsQuery = {
  __typename?: 'Query';
  apps?: Array<{ __typename?: 'AppListItem'; id: string; name: string; type: string } | null> | null;
};

export type GetBranchConfigurationQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetBranchConfigurationQuery = {
  __typename?: 'Query';
  branchConfiguration?: {
    __typename?: 'BranchConfiguration';
    id: string;
    branchKey?: string | null;
    branchDomain?: string | null;
    branchAlternateDomain?: string | null;
    enabled?: boolean | null;
  } | null;
};

export type GetBranchLinkDataQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetBranchLinkDataQuery = {
  __typename?: 'Query';
  branchLinkData?: {
    __typename?: 'BranchLinkData';
    id: string;
    iOSUrl?: string | null;
    androidUrl?: string | null;
    sha256?: string | null;
    teamId?: string | null;
  } | null;
};

export type UpdateBranchLinkDataMutationVariables = Exact<{
  id: Scalars['ID'];
  input: BranchLinkDataInput;
}>;

export type UpdateBranchLinkDataMutation = {
  __typename?: 'Mutation';
  branchLinkData?: {
    __typename?: 'BranchLinkData';
    id: string;
    iOSUrl?: string | null;
    androidUrl?: string | null;
    sha256?: string | null;
    teamId?: string | null;
  } | null;
};

export type GetBuildQueueStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetBuildQueueStatusQuery = {
  __typename?: 'Query';
  buildQueueStatus?: { __typename?: 'BuildQueueStatus'; status: BuildQueueStatuses } | null;
};

export type BuildsCancelMutationVariables = Exact<{
  id: Scalars['ID'];
  input: BuildsCancelInput;
}>;

export type BuildsCancelMutation = { __typename?: 'Mutation'; buildsCancel?: any | null };

export type BuildsStartMutationVariables = Exact<{
  id: Scalars['ID'];
  input: BuildsStartInput;
}>;

export type BuildsStartMutation = { __typename?: 'Mutation'; buildsStart?: any | null };

export type GetCventReleaseVersionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCventReleaseVersionsQuery = {
  __typename?: 'Query';
  cventReleaseVersions?: { __typename?: 'CventReleaseVersions'; versions: Array<string> } | null;
};

export type GetIosImagesQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetIosImagesQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    id: string;
    appDetails: {
      __typename?: 'AppDetails';
      id: string;
      iOSImages: {
        __typename?: 'IOSImages';
        id: string;
        appIcon?: {
          __typename?: 'MediaLibraryImage';
          lastModified?: any | null;
          id?: string | null;
          fileName?: string | null;
          url?: string | null;
        } | null;
        storeIcon?: {
          __typename?: 'MediaLibraryImage';
          lastModified?: any | null;
          id?: string | null;
          fileName?: string | null;
          url?: string | null;
        } | null;
      };
    };
  } | null;
};

export type GetLocaleQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocaleQuery = { __typename?: 'Query'; locale?: string | null };

export type GetPushProvisioningQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetPushProvisioningQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    pushProvisioning: {
      __typename?: 'PushProvisioning';
      certificate?: {
        __typename?: 'PushCertificate';
        filename?: string | null;
        name?: string | null;
        expiration?: any | null;
        subject?: string | null;
        issuer?: string | null;
        organizationalUnit?: string | null;
        lastModified?: any | null;
        fingerprint?: { __typename?: 'CertificateFingerprint'; sha256?: string | null; sha1?: string | null } | null;
      } | null;
      token?: {
        __typename?: 'PushToken';
        filename?: string | null;
        uploadDate?: any | null;
        teamId?: string | null;
        keyId?: string | null;
        lastModified?: any | null;
      } | null;
    };
  } | null;
};

export type GetSigningQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSigningQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    signing: {
      __typename?: 'Signing';
      iOS?: {
        __typename?: 'IOSSigning';
        provisioningProfile?: {
          __typename?: 'ProvisioningProfile';
          appIdName?: string | null;
          expirationDate?: any | null;
          applicationIdentifierPrefix?: string | null;
          filename?: string | null;
          provisioningType?: ProvisioningType | null;
          provisioningName?: string | null;
          teamIdentifier?: string | null;
          signingType?: IosSigningType | null;
          lastModified?: any | null;
          certificateFingerprint?: {
            __typename?: 'CertificateFingerprint';
            sha256?: string | null;
            sha1?: string | null;
          } | null;
        } | null;
        certificate?: {
          __typename?: 'Certificate';
          filename?: string | null;
          name?: string | null;
          expiration?: any | null;
          organizationalUnit?: string | null;
          commonName?: string | null;
          issuer?: string | null;
          subject?: string | null;
          lastModified?: any | null;
          fingerprint?: { __typename?: 'Fingerprint'; sha256?: string | null; sha1?: string | null } | null;
        } | null;
      } | null;
      android?: {
        __typename?: 'AndroidSigning';
        keystore?: {
          __typename?: 'Keystore';
          filename?: string | null;
          name?: string | null;
          expiration?: any | null;
          organizationalUnit?: string | null;
          commonName?: string | null;
          issuer?: string | null;
          subject?: string | null;
          lastModified?: any | null;
          fingerprint?: { __typename?: 'Fingerprint'; sha256?: string | null; sha1?: string | null } | null;
        } | null;
      } | null;
    };
  } | null;
};

export type GetSplashScreenQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetSplashScreenQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    id: string;
    appDetails: {
      __typename?: 'AppDetails';
      id: string;
      splashScreen?: {
        __typename?: 'MediaLibraryImage';
        lastModified?: any | null;
        id?: string | null;
        fileName?: string | null;
        url?: string | null;
      } | null;
    };
  } | null;
};

export type UpdateStoreSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: StoreSettingsInput;
}>;

export type UpdateStoreSettingsMutation = {
  __typename?: 'Mutation';
  storeSettings?: {
    __typename?: 'StoreSettings';
    appleAccountName?: string | null;
    androidAccountName?: string | null;
    storeName?: string | null;
    storeDescription?: string | null;
    storeShortDescription?: string | null;
    storeKeywords?: string | null;
    supportUrl?: string | null;
    privacyPolicyUrl?: string | null;
    appleTeamId?: string | null;
    sellerName?: string | null;
    lastModified?: any | null;
    mainContact?: {
      __typename?: 'MainContact';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
    } | null;
  } | null;
};

export type GetThemeQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetThemeQuery = {
  __typename?: 'Query';
  app?: {
    __typename?: 'App';
    id: string;
    name: string;
    type: string;
    appDetails: {
      __typename?: 'AppDetails';
      theme: {
        __typename?: 'Theme';
        id: string;
        primaryColor?: string | null;
        actionColor?: string | null;
        headerShape?: ThemeHeaderShape | null;
        safeColorMode?: boolean | null;
        preferFormula?: PreferFormula | null;
        lastModified?: any | null;
      };
    };
  } | null;
};

export type UpdateThemeMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ThemeInput;
}>;

export type UpdateThemeMutation = {
  __typename?: 'Mutation';
  theme?: {
    __typename?: 'Theme';
    id: string;
    primaryColor?: string | null;
    actionColor?: string | null;
    headerShape?: ThemeHeaderShape | null;
    safeColorMode?: boolean | null;
    preferFormula?: PreferFormula | null;
  } | null;
};

export const MediaLibraryImageFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaLibraryImageFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImage' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MediaLibraryImageFragmentFragment, unknown>;
export const GetAndroidImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAndroidImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'androidImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'featureGraphic' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaLibraryImageFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetAndroidImagesQuery, GetAndroidImagesQueryVariables>;
export const GetAppDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAppQuery, GetAppQueryVariables>;
export const GetAppDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAppDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'iOSBundleId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'androidBundleId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'androidImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'featureGraphic' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iOSImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storeIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'theme' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'actionColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'headerShape' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storeSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appleAccountName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'androidAccountName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeShortDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storeKeywords' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'appleTeamId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sellerName' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mainContact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAppDetailsQuery, GetAppDetailsQueryVariables>;
export const UpdateAppDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAppDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppDetailsInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'appDetails' },
            name: { kind: 'Name', value: 'updateAppDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iOSBundleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'androidBundleId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateAppDetailsMutation, UpdateAppDetailsMutationVariables>;
export const UpdateSplashScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSplashScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'MediaLibraryImageInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'splashScreen' },
            name: { kind: 'Name', value: 'updateSplashScreen' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateSplashScreenMutation, UpdateSplashScreenMutationVariables>;
export const UpdateAndroidImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAndroidImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AndroidImagesInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'androidImages' },
            name: { kind: 'Name', value: 'updateAndroidImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appIcon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'featureGraphic' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateAndroidImagesMutation, UpdateAndroidImagesMutationVariables>;
export const UpdateIosImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateIOSImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'IOSImagesInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'iOSImages' },
            name: { kind: 'Name', value: 'updateIOSImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appIcon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'storeIcon' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'fileName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateIosImagesMutation, UpdateIosImagesMutationVariables>;
export const UpdateAppDetailsPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAppDetailsPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'appDetailsInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AppDetailsInput' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storeSettingsInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreSettingsInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'appDetails' },
            name: { kind: 'Name', value: 'updateAppDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'appDetailsInput' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iOSBundleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'androidBundleId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'storeSettings' },
            name: { kind: 'Name', value: 'updateStoreSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storeSettingsInput' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appleAccountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'androidAccountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeShortDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeKeywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appleTeamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellerName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainContact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateAppDetailsPageMutation, UpdateAppDetailsPageMutationVariables>;
export const GetOverviewPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOverviewPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationPreferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'android' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iOS' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferencesSet' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'environmentParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codeEnvironment' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iOS' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signing' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'pushNotification' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'android' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'images' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signing' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'appDetails' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'splashScreen' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'theme' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetOverviewPageQuery, GetOverviewPageQueryVariables>;
export const GetApplicationBuildsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationBuilds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'buildInput' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applicationBuilds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'buildInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'buildInput' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'previousToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'builds' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'buildInput' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'buildInput' } }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'buildId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'platform' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distributionBuildType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signingType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedCreatedDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'formattedUpdatedDateTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'version' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cventVersion' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'downloadable' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'errorDescription' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildJobId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'buildRequestId' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetApplicationBuildsQuery, GetApplicationBuildsQueryVariables>;
export const GetApplicationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApplicationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'applicationPreferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'android' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iOS' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'preferencesSet' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'environmentParameters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codeEnvironment' } }]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetApplicationPreferencesQuery, GetApplicationPreferencesQueryVariables>;
export const UpdateApplicationPreferencesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateApplicationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ApplicationPreferencesInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'applicationPreferences' },
            name: { kind: 'Name', value: 'updateApplicationPreferences' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'applicationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'distributionType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'android' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'iOS' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'enabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'signingType' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'preferencesSet' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'environmentParameters' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'codeEnvironment' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateApplicationPreferencesMutation, UpdateApplicationPreferencesMutationVariables>;
export const GetAppsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getApps' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'apps' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAppsQuery, GetAppsQueryVariables>;
export const GetBranchConfigurationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBranchConfiguration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'branchConfiguration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchDomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'branchAlternateDomain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enabled' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBranchConfigurationQuery, GetBranchConfigurationQueryVariables>;
export const GetBranchLinkDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBranchLinkData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'branchLinkData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iOSUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'androidUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBranchLinkDataQuery, GetBranchLinkDataQueryVariables>;
export const UpdateBranchLinkDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateBranchLinkData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BranchLinkDataInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'branchLinkData' },
            name: { kind: 'Name', value: 'updateBranchLinkData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iOSUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'androidUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                { kind: 'Field', name: { kind: 'Name', value: 'teamId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateBranchLinkDataMutation, UpdateBranchLinkDataMutationVariables>;
export const GetBuildQueueStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getBuildQueueStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildQueueStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'status' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBuildQueueStatusQuery, GetBuildQueueStatusQueryVariables>;
export const BuildsCancelDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'buildsCancel' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildsCancelInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildsCancel' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BuildsCancelMutation, BuildsCancelMutationVariables>;
export const BuildsStartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'buildsStart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BuildsStartInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'buildsStart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BuildsStartMutation, BuildsStartMutationVariables>;
export const GetCventReleaseVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getCventReleaseVersions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cventReleaseVersions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'versions' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCventReleaseVersionsQuery, GetCventReleaseVersionsQueryVariables>;
export const GetIosImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getIOSImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iOSImages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'storeIcon' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'MediaLibraryImageFragment' }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaLibraryImageFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetIosImagesQuery, GetIosImagesQueryVariables>;
export const GetLocaleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getLocale' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locale' },
            directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetLocaleQuery, GetLocaleQueryVariables>;
export const GetPushProvisioningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getPushProvisioning' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pushProvisioning' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'certificate' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'organizationalUnit' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fingerprint' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'token' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'teamId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'keyId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPushProvisioningQuery, GetPushProvisioningQueryVariables>;
export const GetSigningDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSigning' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signing' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'iOS' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'provisioningProfile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'appIdName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'applicationIdentifierPrefix' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'certificateFingerprint' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'provisioningType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'provisioningName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'teamIdentifier' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'signingType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'certificate' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'organizationalUnit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'commonName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fingerprint' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'android' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'keystore' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'expiration' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'organizationalUnit' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'commonName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'issuer' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fingerprint' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'sha256' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'sha1' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetSigningQuery, GetSigningQueryVariables>;
export const GetSplashScreenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getSplashScreen' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'splashScreen' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaLibraryImageFragment' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaLibraryImageFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetSplashScreenQuery, GetSplashScreenQueryVariables>;
export const UpdateStoreSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStoreSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StoreSettingsInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'storeSettings' },
            name: { kind: 'Name', value: 'updateStoreSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'appleAccountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'androidAccountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeShortDescription' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storeKeywords' } },
                { kind: 'Field', name: { kind: 'Name', value: 'supportUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyPolicyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appleTeamId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sellerName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mainContact' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateStoreSettingsMutation, UpdateStoreSettingsMutationVariables>;
export const GetThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'app' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'theme' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'actionColor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'headerShape' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'safeColorMode' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'preferFormula' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastModified' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetThemeQuery, GetThemeQueryVariables>;
export const UpdateThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ThemeInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'theme' },
            name: { kind: 'Name', value: 'updateTheme' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'primaryColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'actionColor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'headerShape' } },
                { kind: 'Field', name: { kind: 'Name', value: 'safeColorMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'preferFormula' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateThemeMutation, UpdateThemeMutationVariables>;
