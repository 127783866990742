import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import { ApolloClientFactory } from '@cvent/apollo-client/ApolloClientFactory';
import { CSRFToken } from '../server/types';
import { constants as authConstants } from '@cvent/nextjs/auth';
import { getClientApolloOptions } from './apolloOptions';
import { useMemo } from 'react';
import { useApollo as useNxApollo } from '@cvent/apollo-client/useApollo';

const { CSRF_HEADER } = authConstants;
export type ApolloPageProps = Parameters<typeof useNxApollo>[0] & { auth: CSRFToken };

// Creating once so they are not recreated everytime useApollo is called.
const clientFactory = new ApolloClientFactory('', '');

/**
 * A hook to access the apollo client in the browser.
 * @param pageProps The props including __APOLLO_STATE__ to hydrate the apollo cache.
 */
export const useApollo = (pageProps: ApolloPageProps): ApolloClient<NormalizedCacheObject> => {
  const headers = useMemo(() => pageProps.auth && { [CSRF_HEADER]: pageProps.auth.token }, [pageProps]);
  const options = useMemo(() => getClientApolloOptions(headers), [headers]);
  return useNxApollo(pageProps, clientFactory, options);
};
