export const defaultLocale = 'en-US';

export interface LocaleEntry {
  default?: Record<string, string>;
}

const resolveLocales =
  (...localeImports: Array<Promise<LocaleEntry>>) =>
  async (): Promise<LocaleEntry> => {
    const resolvedImports = await Promise.all([...localeImports]);
    return resolvedImports.reduce(
      (acc, value) => ({
        ...acc,
        ...value,
        default: {
          ...acc.default,
          ...value.default
        }
      }),
      {}
    );
  };

const locales = {
  'en-US': resolveLocales(import('@cvent/media-library/resources/locales/en-US.json'), import('./en-US.json')),
  'de-DE': resolveLocales(import('@cvent/media-library/resources/locales/de-DE.json'), import('./de-DE.json')),
  'es-ES': resolveLocales(import('@cvent/media-library/resources/locales/es-ES.json'), import('./es-ES.json')),
  'fi-FI': resolveLocales(import('@cvent/media-library/resources/locales/fi-FI.json'), import('./fi-FI.json')),
  'fr-CA': resolveLocales(import('@cvent/media-library/resources/locales/fr-CA.json'), import('./fr-CA.json')),
  'fr-FR': resolveLocales(import('@cvent/media-library/resources/locales/fr-FR.json'), import('./fr-FR.json')),
  'it-IT': resolveLocales(import('@cvent/media-library/resources/locales/it-IT.json'), import('./it-IT.json')),
  'ja-JP': resolveLocales(import('@cvent/media-library/resources/locales/ja-JP.json'), import('./ja-JP.json')),
  'ko-KR': resolveLocales(import('@cvent/media-library/resources/locales/ko-KR.json'), import('./ko-KR.json')),
  'nl-NL': resolveLocales(import('@cvent/media-library/resources/locales/nl-NL.json'), import('./nl-NL.json')),
  'pt-BR': resolveLocales(import('@cvent/media-library/resources/locales/pt-BR.json'), import('./pt-BR.json')),
  'pt-PT': resolveLocales(import('@cvent/media-library/resources/locales/pt-PT.json'), import('./pt-PT.json')),
  'ru-RU': resolveLocales(import('@cvent/media-library/resources/locales/ru-RU.json'), import('./ru-RU.json')),
  'sv-SE': resolveLocales(import('@cvent/media-library/resources/locales/sv-SE.json'), import('./sv-SE.json')),
  'th-TH': resolveLocales(import('@cvent/media-library/resources/locales/th-TH.json'), import('./th-TH.json')),
  'tr-TR': resolveLocales(import('@cvent/media-library/resources/locales/tr-TR.json'), import('./tr-TR.json')),
  'zh-Hans': resolveLocales(import('@cvent/media-library/resources/locales/zh-Hans.json'), import('./zh-Hans.json')),
  'zh-Hant': resolveLocales(import('@cvent/media-library/resources/locales/zh-Hant.json'), import('./zh-Hant.json'))
} as LocaleEntry;

export default locales;
