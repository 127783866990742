import { rumConfig } from '../config/initBrowserLogging';
/* eslint-disable @typescript-eslint/no-empty-function */
import { initRum } from '@cvent/nextjs/datadog/initRum';
import getConfig from 'next/config';
import { ApolloProvider, makeVar } from '@apollo/client';
import '@cvent/carina/fonts/fonts.web.css';
import 'normalize.css'; // css reset
import { ApolloPageProps, useApollo } from '@client/useApollo';
import AppProviders from '@components/AppProviders';
import type { AppProps } from 'next/app';
import { useEffect } from 'react';

const { publicRuntimeConfig } = getConfig();

type BrandedAppProps = Pick<AppProps, 'Component'> & { pageProps: ApolloPageProps };

export const clientVersion = makeVar<string>(String(publicRuntimeConfig.CLIENT_VERSION) || 'unknown');

export default function App({ Component, pageProps }: BrandedAppProps): JSX.Element {
  initRum(rumConfig);

  useEffect(() => {
    // Force logging in browser
    // eslint-disable-next-line no-console
    console.log(`App Version: ${clientVersion()}`);
    // We only want this to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This client setup uses the splitlink defined in useApolloClientOptions
  const client = useApollo(pageProps);

  return (
    <ApolloProvider client={client}>
      <AppProviders>
        <Component {...pageProps} />
      </AppProviders>
    </ApolloProvider>
  );
}
